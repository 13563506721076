import ready from './lib/Ready';

ready(() => {
    // mobile-first: iOS displays the keyboard for autofocus fields even when autocompleted, getting in the way of
    // tapping the Login button. however, autofocus in desktop browsers allows hitting 'enter' for autocompleted details
    if (window.screen.width > 1024) {
        document.querySelector('input[type="email"]').focus();
    }

    // Prevent double login form submission, which can sometimes occur with 1Password browser plugin.
    document.querySelector('form').addEventListener('submit', (event) => {
        event.target.querySelector('button[type="submit"]').disabled = true;
    });
});
